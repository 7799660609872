<template>
  <div
    class="relative px-2 py-3 font-bold text-center rounded-md"
    @click="handleClick"
    :class="classes">
    <span>{{ label }}</span>
    <div
      v-if="icon"
      class="absolute p-1 rounded-full bg-sky-50 -right-2 -top-2">
      <component
        :is="icon"
        class="text-blue-500" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hour: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    available: {
      type: Number,
      default: 0
    },
    booked: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: null
    }
  },
  name: "Slot",
  data() {
    return { extra: false }
  },
  computed: {
    colorClasses() {
      if (this.isLoading) {
        return ["bg-gray-200 text-gray-200"]
      }

      if (!this.isActive) {
        return ["bg-transparent text-black"]
      }

      const percentBooked = this.booked / this.total * 100
      if (percentBooked > 80) {
        return ["bg-red-500 hover:bg-red-600 text-white"]
      }

      if (percentBooked > 40) {
        return ["bg-orange-400 hover:bg-orange-500 text-white"]
      }

      if (percentBooked > 0) {
        return ["bg-yellow-400 hover:bg-yellow-500 text-white"]
      }

      return ["bg-green-500 hover:bg-green-600 text-white"]
    },
    classes() {
      return [
        "transition-[background-color] duration-300",
        ...this.colorClasses,
        this.isActive ? "cursor-pointer" : ""
      ]
    },
    isActive() {
      return this.available && !this.isLoading
    },
    label() {
      if (!this.isActive) {
        return "-"
      }

      return this.extra ? `${this.available} sur ${this.total}` : this.hour
    }
  },
  methods: {
    handleClick() {
      if (this.isActive) {
        this.extra = !this.extra
      }
    }
  }
}
</script>
