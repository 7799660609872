import "./styles/tailwind.css"
import "animate.css"

import App from "./App.vue"
import { createApp } from "vue"
import router from "./router"
import store from "./store"
import StringService from "@/services/StringService"

const app = createApp(App)
app.use(store)
app.use(router)
app.mount("#app")

// Filters
app.config.globalProperties.$filters = {
  ucfirst(value) {
    return StringService.ucfirst(value)
  }
}

// https://momentjs.com/docs/
import moment from "moment"
moment.locale("fr")
app.config.globalProperties.$moment = moment

// Material design icons
import Loading from "vue-material-design-icons/Loading.vue"
app.component("loading", Loading)
import ChevronRight from "vue-material-design-icons/ChevronRight.vue"
app.component("chevron-right", ChevronRight)
import ChevronLeft from "vue-material-design-icons/ChevronLeft.vue"
app.component("chevron-left", ChevronLeft)
import Star from "vue-material-design-icons/Star.vue"
app.component("star", Star)
